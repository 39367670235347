import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { Spinner } from 'react-bootstrap';
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

import AWS from './components/aws'
import Config from './components/config'
import Session from './components/Session'

Amplify.configure({
  Auth: AWS.cognito.Auth
});
const oauth = AWS.cognito.oauth
Auth.configure({oauth});

class Index extends Component {
  constructor(){
    super();
    this.state = {
      authState: 'loading'
    };

    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          this.currentAuthUser();
          break;
        default:
          break;
      }
    });
  }

  componentDidMount() {
    if (Config.config.sso) {
      setTimeout(() => {this.currentAuthUser();}, 1500);
    } else {
      this.getUser(null);
    }
  }

  currentAuthUser() {
    this.state.authState==='loading' &&
      Auth.currentAuthenticatedUser()
      .then((r) => {
        this.getUser(r);
      })
      .catch((e) => {
        this.signIn();
      })
  }

  async signIn() {
    try {
      await Auth.federatedSignIn();
    } catch (e) {
      console.log(e);
      this.setState({ authState: 'error' });
    }
  }

  async getUser(user) {
    const url = Config.config.serverUrl + '/login';
    const token = Config.config.sso ? user.signInUserSession.idToken.jwtToken : null;
    axios
      .post(url,
            { jwtToken: token },
            { withCredentials: (Config.config.lambda) ? false : true })
      .then((r) => {
        this.setState({ 
          authState: 'signedIn',
          filenames: r.data.filenames
       });
      })
      .catch((e) => {
        console.log(e);
        this.setState({ authState: 'error' });
      })
  }

  render() {
    const { authState } = this.state;
    return (
      <>
        { authState==='loading' &&
          <div className="spinner">
            <Spinner animation="grow" variant="secondary"></Spinner><span className="loading">Loading...</span>
          </div>
        }
        { authState==='signedIn' &&
          <Session app={this.state.app} filenames={this.state.filenames} />
        }
        { authState==='error' &&
          <div className="login_msg">ログインエラー</div>
        }
      </>
    );
  }
}

ReactDOM.render(
  <Router>
    <Switch>
      <Route exact path="/" component={Index}/>
    </Switch>
  </Router>, 
  document.getElementById('root')
);

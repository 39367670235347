class Config {
  static get config() {

    const ENV = 'prod_demo';
    const APP = 'recommend';
    
    let production;
    let sso;
    let lambda;
    let serverUrl = '';
    const url = '192.168.1.177';

    if (ENV==='prod' || ENV==='prod_demo') {
      production  = true;
      sso         = true;
      lambda      = true;
      if (ENV==='prod') {
        if (APP==='recommend') {
          serverUrl   = 'https://t2e14kjr68.execute-api.ap-northeast-1.amazonaws.com/Prod'; 
        } else if (APP==='training') {
          serverUrl   = 'https://5yafmup7gh.execute-api.ap-northeast-1.amazonaws.com/Prod';
        }
      } else if (ENV==='prod_demo') {
        if (APP==='recommend') {
          serverUrl   = 'https://bk513kzjeg.execute-api.ap-northeast-1.amazonaws.com/Prod';
        } else if (APP==='training') {
          serverUrl   = 'https://2w4hry6ivl.execute-api.ap-northeast-1.amazonaws.com/Prod';
        }
      }
    } 
    
    else if (ENV==='dev_ec2') {
      sso         = true;
    } 
    
    else if (ENV==='dev_lambda') {
      lambda      = true;
      // serverUrl   = 'https://pw1ads8z2i.execute-api.ap-northeast-1.amazonaws.com/Prod';   -server
      serverUrl   = 'https://jxbvsmzeha.execute-api.ap-northeast-1.amazonaws.com/Prod';   // -dev
    } 
    
    else if (ENV==='dev_node') {
      serverUrl   = `http://${url}:4001`;
    }

    return({
      env:          ENV,
      app:          APP,
      production:   production===true ? true : false,
      sso:          sso===true ? true : false,
      lambda:       lambda===true ? true : false,
      serverUrl:    serverUrl,
    });
  }
}

export default Config;

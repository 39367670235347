import Config from './config';

class Aws {
  static get cognito() {
    let userPoolId;
    let client_id;
    let domain;
    let redirect_uri;

    if (Config.config.production) {
      // TFA 本番環境
      userPoolId    = 'ap-northeast-1_jJaWSWMaY';
      client_id     = '3ajpguo5vql0i7op9m7hc73ge6';
      domain        = 'tfa-recommend-training-app-settings.auth.ap-northeast-1.amazoncognito.com';

      if (Config.config.env==='prod') {
        redirect_uri  = `https://tfa.${Config.config.app}.settings.tiifa.jp`;
      } else if (Config.config.env==='prod_demo') {
        redirect_uri  = `https://demo-tfa-${Config.config.app}-settings.tiifa.jp`;
      }
    }

    return ({
      Auth: {
        userPoolId: userPoolId,
        userPoolWebClientId: client_id,
        authenticationFlowType: 'USER_PASSWORD_AUTH', // USER_SRP_AUTH' | 'USER_PASSWORD_AUTH' | 'CUSTOM_AUTH',    
      },
      oauth: {
        domain: domain,
        redirectSignIn: redirect_uri,
        responseType: 'code'
      }
    });
  }

  static get s3() {
    let accessKeyId;
    let secretAccessKey;
    let region;
    let bucketname;

    if (Config.config.production) {
      // TFA 本番環境 TFA-recommend-training-admin
      accessKeyId     = 'AKIA45OTQLKMDUFVA6XX';
      secretAccessKey = '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';
      region          = 'ap-northeast-1';
      bucketname      = 'tfa-import-files-recommend-training';
    } else {
      // NC
      accessKeyId     = 'AKIAUFYCHNGA37XJ5XXN';
      secretAccessKey = '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';
      region          = 'ap-northeast-1';
      bucketname      = 'import-gui-files';
    }

    return ({
      credentials: {
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
        region: region,
        signatureVersion: 'v4'
      },
      bucketname: bucketname,
    });
  }
}

export default Aws;
